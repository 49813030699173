<template>
  <q-btn-group rounded>
    <q-btn :color="option.id === value ? 'primary' : 'white'"
           :text-color="option.id === value ? 'white' : 'black'"
           no-caps
           @click="select(option)"
           size="sm" :label="option.label"
           v-for="option in options"
           class="text-capitalize"
           :key="option.id"/>
  </q-btn-group>
</template>
<script>
export default {
  props: {
    value: {type: String, default: 'round-trip'},
    languages: {type: Object, default: () => ({})}
  },
  data () {
    return {
      options: [
        {
          label: this.languages['common.round-trip'] || 'Round Trip',
          id: 'round-trip'
        },
        {
          label: this.languages['common.one-way'] || 'One Way',
          id: 'one-way'
        }
      ]
    }
  },
  methods: {
    select (option) {
      this.$emit('input', option.id)
    }
  }
}
</script>