<template>
  <div class="gq-search-form__content">
    <q-form ref="form" greedy
            @submit="$emit('submit')"
            class="q-row q-col-gutter-sm eg-form-wrap">
      <div class="q-col-12">
        <route-switch v-model="value.route_type" :languages="languages" />
      </div>
      <div class="q-col-12 q-col-md-4">
        <region-select :label="origin_label" outlined dense
                      v-model="value.origin"
                      :rules="rules.origin"
                      :types="['airport']"
                      :static_route_enabled="static_route_enabled"
                      :fetch_on_click="static_route_enabled"
                      :clearable="static_route_enabled"
                      :destination_type="static_route_enabled ? 'departure' : ''"
                      :arrival_code="static_route_enabled && value.destination ? value.destination.code : ''"
                      prepend_icon="flight_takeoff"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <region-select :label="destination_label" outlined dense
                      v-model="value.destination"
                      :rules="rules.destination"
                      :types="['airport']"
                      :static_route_enabled="static_route_enabled"
                      :fetch_on_click="static_route_enabled"
                      :clearable="static_route_enabled"
                      :destination_type="static_route_enabled ? 'arrival' : ''"
                      :departure_code="static_route_enabled && value.origin ? value.origin.code : ''"
                      prepend_icon="place"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <template v-if="value.route_type === 'round-trip'">
          <date-picker prepend_icon="event" dense
                      outlined range only_future
                      :rules="rules.date_range"
                      @input="handle_date_changes"
                      :value="{from: value.start_date, to: value.end_date}"
                      :label="departure_return_label" />
        </template>
        <template v-else>
          <date-picker prepend_icon="event"
                      outlined only_future dense
                      :rules="rules.date"
                      v-model="value.start_date"
                      :label="departure_label" />
        </template>
      </div>
      <div class="q-col-12 q-col-md-4">
        <pax-picker outlined dense
                    :child_age="context.age_policy.child_age"
                    :infant_age="context.age_policy.infant_age"
                    v-model="value.paxes"
                    prepend_icon="people"
                    :languages="languages"
                    :traveler_types="configs ? configs.traveler_types : []"
                    :max_adults="traveler_configs.max_adults"
                    :max_children="traveler_configs.max_children"
                    :max_infants="traveler_configs.max_infants"
                    :total_traveler_limit="traveler_configs.total_traveler_limit"
                    :traveler_limit_type="traveler_configs.traveler_limit_type"
                    :label="passengers_label"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <cabin-select with_rooms outlined dense
                      v-model="value.cabin_class"
                      prepend_icon="class"
                      :languages="languages"
                      :cabin_classes="configs ? configs.cabin_classes : []"
                      :label="cabin_class_label"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <search-btn :languages="languages" />
      </div>
    </q-form>
  </div>
</template>

<script>
import RegionSelect from '../../helpers/region-select'
import SearchBtn from '../../helpers/search-btn'
import PaxPicker from '../../helpers/pax-picker'
import CabinSelect from '../../helpers/cabin-select'
import RouteSwitch from '../../helpers/route-switch'
import DatePicker from '../../helpers/date-picker'

export default {
  components: {
    RegionSelect,
    SearchBtn,
    PaxPicker,
    CabinSelect,
    RouteSwitch,
    DatePicker
  },
  props: {
    context: Object,
    frontend_url: String,
    value: Object,
    languages: {type: Object, default: () => ({})},
    configs: {type: Object},
    traveler_configs: {type: Object, default: () => ({})}
  },
  data() {
    return {
      rules: {
        origin: [
          v => !!v || ''
        ],
        destination: [
          v => !!v || ''
        ],
        date_range: [
          v => (!!v.from && !!v.to) || ''
        ],
        date: [
          v => !!v || ''
        ],
        paxes: [],
      }
    }
  },
  computed: {
    static_route_enabled () {
      const flight = this.context.products.find(i => i.code === 'flight')
      return flight ? flight.static_route_enabled : false
    },
    origin_label () {
      return this.languages['flight.origin'] || 'flight.origin'
    },
    destination_label () {
      return this.languages['flight.destination'] || 'flight.destination'
    },
    departure_return_label () {
      const dep = this.languages['flight.departure'] || 'flight.departure'
      const ret = this.languages['flight.return'] || 'flight.return'
      return `${dep} / ${ret}`
    },
    departure_label () {
      return this.languages['flight.departure'] || 'flight.departure'
    },
    passengers_label () {
      return this.languages['flight.passengers'] || 'flight.passengers'
    },
    cabin_class_label () {
      return this.languages['flight.cabin-class'] || 'flight.cabin-class'
    },
  },
  methods: {
    handle_date_changes (val) {
      this.value.start_date = val.from
      this.value.end_date = val.to
    }, 
  }
}
</script>