import Vue from "vue";

//axios
import "./styles/styles.scss";
import "./plugins/quasar/src/css/index.sass";
import axios from "axios";
import services from "./services";
import Quasar from "./plugins/quasar/src/index.esm";

Vue.use(services);
Vue.use(Quasar);

import SearchForm from "./components/forms";
import QuickSearch from "./components/quick-search";

let frontend_url = "";
let language_code = "en-US";

let combine = document.querySelectorAll("[combine]");
let package_url = document.querySelectorAll("[package-id]");
let quick_search_url = document.getElementById("gq-quick-search");

if (combine && combine.length > 0) {
  frontend_url = combine[0].getAttribute("app-url");
  language_code = combine[0].getAttribute("language-code");

  const containers = [...document.querySelectorAll(".gq-shopping-form")];

  containers.forEach((container) => {
    const div = document.createElement("div");
    div.className = "gq-content";
    container.appendChild(div);
  });
}

if (package_url && package_url.length > 0) {
  frontend_url = package_url[0].getAttribute("app-url");

  const containers = [...document.querySelectorAll(".gq-shopping-form")];

  containers.forEach((container) => {
    const div = document.createElement("div");
    div.className = "gq-content";
    container.appendChild(div);
  });
}

if (quick_search_url) {
  frontend_url = quick_search_url.getAttribute("app-url");
  const loader = document.createElement("div");
  loader.className = "gq-quick-search__content";
  document.getElementById("gq-quick-search").appendChild(loader);
}

if (frontend_url) {
  const api_url = frontend_url + "/api";
  const containers = [...document.querySelectorAll(".gq-shopping-form")];

  // $('body').append(`
  //     <div :style="display: flex" class="loader-wrap">
  //         <div class="loader"></div>
  //     </div>
  // `)

  const loading = document.createElement("div");
  loading.className = "gq-loading";
  document.body.appendChild(loading);

  axios.get(`${api_url}/context?language_code=${language_code}`).then(async (res) => {
    if (document.getElementsByClassName("gq-shopping-form").length) {
      const language_res = await axios.get(`${api_url}/language/json?language_code=${language_code}`)
      const form_res = await axios.get(`${api_url}/cms/shopping-forms`)
      const package_res = await axios.get(`${api_url}/packages/list`)
      
      containers.forEach((container) => {
        const combine = container.getAttribute("combine");

        const package_id = container.getAttribute("package-id");
        const selected_package = form_res.data.find(
          (i) => i.id === package_id
        );
        
        if (selected_package) {
          new Vue({
            el: ".gq-content",
            render: (h) =>
              h(SearchForm, {
                props: {
                  frontend_url,
                  context: res.data,
                  combine: selected_package.products
                    .map((p) => p.code)
                    .join("--"),
                  languages: language_res.data || {},
                  language_code,
                  search_packages: package_res.data.result
                },
              }),
          });
        }

        if (combine) {
          new Vue({
            el: ".gq-content",
            render: (h) =>
              h(SearchForm, {
                props: {
                  frontend_url,
                  context: res.data,
                  combine,
                  languages: language_res.data || {},
                  language_code,
                  search_packages: package_res.data.result
                },
              }),
          });
        }
      });
    }

    loading.className = "hide";
  });
}
