<template>
  <div>
    <q-select
      v-if="value"
      v-bind="v_bind"
      :value="form.destination"
      :label="dropoff_location_label"
      ref="input"
      dense
      @filter="filter"
      @input="handle_value_changed"
    >
      <template v-slot:prepend>
        <q-icon class="cursor-pointer" @click="toggle" name="check_box"/>
      </template>
      <template v-slot:option="scope">
        <q-item
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <q-item-section>
            <q-item-label v-html="scope.opt.name" lines="1"/>
            <q-item-label caption v-if="scope.opt.long_name"
                          lines="1"
                          v-html="scope.opt.long_name"/>
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="flight" v-if="scope.opt.type === 'airport'"/>
            <q-icon name="place" v-else/>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
    <q-field outlined v-else dense>
      <template v-slot:control>
        <span
          class="cursor-pointer"
          @click="toggle"
        >
          {{ diff_location_label }}
        </span>
      </template>
      <template v-slot:prepend>
        <q-icon class="cursor-pointer" @click="toggle" name="check_box_outline_blank"/>
      </template>
    </q-field>
  </div>
</template>
<script>

export default {
  props: {
    value: {type: Boolean, default: false},
    form: {type: Object, required: true},
    languages: {type: Object, default: () => ({})},
  },
  data () {
    return {
      rules: [
        v => !!v || ''
      ],
      options: []
    }
  },
  computed: {
    v_bind () {
      return {
        outlined: true,
        options: this.options,
        rules: this.rules,
        'use-input': true,
        'hide-bottom-space': true,
        'fill-input': true,
        'hide-selected': true,
        'input-debounce': 500,
        'option-label': 'name',
        'option-value': 'id',
      }
    },
    dropoff_location_label () {
      return this.languages['car-rental.dropoff-location'] || 'car-rental.dropoff-location'
    },
    diff_location_label () {
      return this.languages['car-rental.different-dropoff'] || 'car-rental.different-dropoff'
    }
  },
  methods: {
    toggle () {
      this.$emit('input', !this.value)
    },
    validate () {
      return this.$refs.input.validate()
    },
    async filter (search_text, update, abort) {
      if (!search_text && this.options.length) return update()
      this.options = await this.list(search_text)
      update()
    },
    async list (search_text) {
      const service = new this.$services.StorageService(this)
      let payload = {
        search_text: search_text,
        language_code: 'en-US',
        per_page: 20,
        types: 'airport',
      }
      const response = await service.list_places(payload)
      if (response.status === 200) {
        return response.data.places
      }
      return []
    },
    handle_value_changed (val) {
      this.form.destination = {
        _id: val._id,
        id: val.id,
        name: val.name,
        long_name: val.long_name,
        type: val.type,
        code: val.code,
        location: val.location,
        ancestors: val.ancestors
      }
    }
  },
}
</script>
