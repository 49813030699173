
import StorageService from './storage'

export default {
  install: (Vue, options) => {
    Vue.prototype.$services = {
      StorageService,
    }
  }
}
 