<template>
  <div style="max-width: 1200px; margin: auto; padding: 15px">
    <q-form ref="form" greedy
            @submit="$emit('submit')"
            class="q-row q-col-gutter-sm">
      <div class="q-col-12 q-col-md-3">
        <region-select :label="origin_label" outlined dense
                        v-model="value.origin"
                        :rules="rules.origin"
                        :types="['airport']"
                        :static_route_enabled="static_route_enabled"
                        :fetch_on_click="static_route_enabled"
                        :clearable="static_route_enabled"
                        :destination_type="static_route_enabled ? 'departure' : ''"
                        :departure_code="static_route_enabled && value.destination ? value.destination.code : ''"
                        prepend_icon="flight_takeoff"/>
      </div>
      <div class="q-col-12 q-col-md-3">
        <region-select :label="destination_label" outlined dense
                        v-model="value.destination"
                        :rules="rules.destination"
                        :types="['airport']"
                        :static_route_enabled="static_route_enabled"
                        :fetch_on_click="static_route_enabled"
                        :clearable="static_route_enabled"
                        :destination_type="static_route_enabled ? 'arrival' : ''"
                        :departure_code="static_route_enabled && value.origin ? value.origin.code : ''"
                        prepend_icon="place"/>
      </div>
      <div class="q-col-12 q-col-md-3 q-mr-sm">
        <date-picker prepend_icon="event" dense
                      outlined range only_future
                      :rules="rules.date_range"
                      @input="handle_date_changes"
                      :value="{from: value.start_date, to: value.end_date}"
                      :label="departure_return_label"/>
      </div>
      <div class="q-col-12 q-col-md-3">
        <pax-picker with_rooms outlined dense
                    v-model="value.paxes"
                    prepend_icon="people"
                    :languages="languages"
                    :child_age="context.age_policy.child_age"
                    :infant_age="context.age_policy.infant_age"
                    :traveler_types="configs ? configs.traveler_types : []"
                    :max_adults="traveler_configs.max_adults"
                    :max_children="traveler_configs.max_children"
                    :max_infants="traveler_configs.max_infants"
                    :total_traveler_limit="traveler_configs.total_traveler_limit"
                    :traveler_limit_type="traveler_configs.traveler_limit_type"
                    :label="passengers_label"/>
      </div>
      <div class="q-col-12 q-col-md-3">
        <cabin-select with_rooms outlined dense
                      v-model="value.cabin_class"
                      :languages="languages"
                      prepend_icon="class"
                      :cabin_classes="configs ? configs.cabin_classes : []"
                      :label="cabin_class_label"/>
      </div>
      <div class="q-col-12 q-col-md-3">
        <separate-checkinout dense v-model="value.separate_checkinout" :form="value"
                              :languages="languages" />
      </div>
      <div class="q-col-12 q-col-md-3">
        <search-btn :languages="languages" />
      </div>
    </q-form>
  </div>
</template>

<script>
import RegionSelect from '../../helpers/region-select'
import SearchBtn from '../../helpers/search-btn'
import PaxPicker from '../../helpers/pax-picker'
import CabinSelect from '../../helpers/cabin-select'
import DatePicker from '../../helpers/date-picker'
import SeparateCheckinout from '../../helpers/separate-check-inout'

export default {
  components: {
    RegionSelect,
    DatePicker,
    SearchBtn,
    PaxPicker,
    CabinSelect,
    SeparateCheckinout,
  },
  props: {
    context: Object,
    frontend_url: String,
    value: Object,
    languages: {type: Object, default: () => ({})},
    configs: {type: Object},
    traveler_configs: {type: Object, default: () => ({})}
  },
  data () {
    return {
      rules: {
        origin: [
          v => !!v || ''
        ],
        destination: [
          v => !!v || ''
        ],
        date_range: [
          v => (!!v.from && !!v.to) || ''
        ],
        paxes: [],
      }
    }
  },
  computed: {
    static_route_enabled () {
      const flight = this.context.products.find(i => i.code === 'flight')
      return flight ? flight.static_route_enabled : false
    },
    origin_label () {
      return this.languages['bundle.origin'] || 'bundle.origin'
    },
    destination_label () {
      return this.languages['bundle.destination'] || 'bundle.destination'
    },
    departure_return_label () {
      const dep = this.languages['bundle.departure'] || 'bundle.departure'
      const ret = this.languages['bundle.return'] || 'bundle.return'
      return `${dep} / ${ret}`
    },
    departure_label () {
      return this.languages['bundle.departure'] || 'bundle.departure'
    },
    passengers_label () {
      return this.languages['bundle.passengers'] || 'bundle.passengers'
    },
    cabin_class_label () {
      return this.languages['bundle.cabin-class'] || 'bundle.cabin-class'
    },
  },
  methods: {
    handle_date_changes (val) {
      this.value.start_date = val.from
      this.value.end_date = val.to
    }
  }
}
</script>