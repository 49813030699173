
 import Axios from 'axios'


let frontend_url = ''

let combine = document.querySelectorAll('[combine]');
let package_url = document.querySelectorAll('[package-id]');
let quick_search_url = document.getElementById('gq-quick-search');

if (combine && combine.length > 0) {
  frontend_url = combine[0].getAttribute('app-url')
}

if (package_url && package_url.length > 0) {
  frontend_url = package_url[0].getAttribute('app-url')
}

if (quick_search_url) {
  frontend_url = quick_search_url.getAttribute('app-url')
}

class Service {
  constructor (context) {
    this.axios = Axios.create({
      // set default BACKEND_API
      baseURL: frontend_url + '/api',
      withCredentials: true
    })
    this.context = context
  }

  make_request_data ({method, payload, headers = null, handle_progress = null}) {
    let full_headers = ''
    if (headers) {
      Object.assign(full_headers, headers)
    }
    if (method === 'get' || method === 'delete') {
      return [{
        headers: full_headers,
        params: payload
      }]
    } else if (method === 'post' || method === 'put' || method === 'patch') {
      const options = {}
      if (typeof handle_progress === 'function') options['onUploadProgress'] = handle_progress
      return [
        payload,
        {headers: full_headers, ...options}
      ]
    }
  }

  start_request (endpoint, request) {
    // make the proper data for method before requesting
    let request_data = this.make_request_data(request)

    // do request
    return this.axios[request.method](endpoint, ...request_data).then(
      response => response
    ).catch(
      error => error.response
    )
  }

  async do_request (endpoint, request) {
    if (!request.payload || !(request.payload instanceof Object)) request.payload = {}

    // start request
    const response = await this.start_request(endpoint, request)

    // end request
    return response
  }
}

export default Service
