<template>
  <q-select v-bind="v_bind"
            @input="val => $emit('input', val)"
            ref="input">
    <template v-slot:append v-if="append_icon">
      <q-icon :name="append_icon"/>
    </template>
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon"/>
    </template>
    <template v-slot:option="scope">
      <q-item
        v-bind="scope.itemProps"
        v-on="scope.itemEvents"
      >
        <q-item-section>
          <q-item-label
            class="text-capitalize"
            :dom-key="scope.opt.label"
            v-html="languages[scope.opt.label] || scope.opt.init_label"
          />
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:selected v-if="selected_option">
      <span>
        {{languages[selected_option.label] || selected_option.init_label}}
      </span>
    </template>
  </q-select>
</template>
<script>

export default {
  props: {
    label: {type: String, default: 'Cabin Class'},
    append_icon: {type: String,},
    prepend_icon: {type: String,},
    outlined: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    value: {type: String},
    languages: {type: Object, default: () => ({})},
    cabin_classes: {type: Array, default: () => ([])}
  },
  data () {
    return {
      options: [
        {
          id: 'Economy',
          label: 'components.shopping.forms.common.cabin-select.options.economy',
          init_label: 'Economy'
        },
        {
          id: 'Premium Economy',
          label: 'components.shopping.forms.common.cabin-select.options.premium',
          init_label: 'Premium Economy'
        },
        {
          id: 'Business',
          label: 'components.shopping.forms.common.cabin-select.options.business',
          init_label: 'Business'
        },
        {
          id: 'First',
          label: 'components.shopping.forms.common.cabin-select.options.first',
          init_label: 'First'
        },
      ]
    }
  },
  computed: {
    v_bind () {
      return {
        label: this.label,
        outlined: this.outlined,
        dense: this.dense,
        value: this.value,
        options: this.options,
        'option-value': 'id',
        'option-label': 'label',
        'map-options': true,
        'emit-value': true
      }
    },
    validate () {
      return this.$refs.input.validate()
    },
    selected_option () {
      return this.options.find(o => o.id === this.value)
    }
  },
  mounted() {
    if (this.cabin_classes.length > 0) {
      let result = []

      this.options.forEach(opt => {
        if (this.cabin_classes.find(i => i === opt.id)) {
          result.push(opt)
        }
      })

      this.options = result
    }
  },
}
</script>