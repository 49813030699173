<template>
  <q-card style="border-radius: 10px; cursor: pointer"
          @click="select">
    <q-img height="150px" :src="avatar_url">
      <div class="absolute-full flex">
        <div class="flex q-fit items-end justify-between">
          <div>
            <q-item-label class="text-h5 text-weight-bold">
              {{ name }}
            </q-item-label>
            <q-item-label v-if="hotel_address" class="flex item-center">
              <q-icon name="map" />
              <span class="q-ml-xs">{{ hotel_address }}</span>
            </q-item-label>
            <q-item-label v-else>
              {{ hotel_count }} hotels
            </q-item-label>
          </div>
        </div>
      </div>
    </q-img>
    <q-card-section horizontal class="justify-between">
      <q-item class="block">
        <q-item-label class="caption">Start from</q-item-label>
        <q-item-label v-if="price_from" class="text-h5 text-weight-bold text-primary">
          ${{ price_from }}
        </q-item-label>
      </q-item>
      <q-item class="block">
        <div class="flex items-center justify-end">
          <template v-for="(product, index) in products">
            <span v-if="index === 1" :key="`plus-${product}`"
                  class="q-mx-xs">
              +
            </span>
            <q-icon :key="product" :name="get_product_icon(product)" size="sm" />
          </template>
        </div>
        <q-item-label class="caption q-mt-xs" style="color: #777">
          {{ description }}
        </q-item-label>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: {
    item: {type: Object, required: true}
  },
  computed: {
    avatar_url () {
      return this.item.avatar && this.item.avatar.value ? this.item.avatar.value.url : ''
    },
    name () {
      return this.item.region && this.item.region.value ? this.item.region.value.name : ''
    },
    hotel_address () {
      const region = this.item.region ? this.item.region.value : {}
      if (region.type === 'hotel') {
        return region.hotel_addr || ''
      }
      return null
    },
    hotel_count () {
      return this.item.hotel_count ? this.item.hotel_count.value : ''
    },
    price_from () {
      return this.item.price_from ? this.item.price_from.value : ''
    },
    description () {
      return this.item.description && this.item.description.value ? this.item.description.value['en-US'] : ''
    },
    products () {
      const products = this.item.package && this.item.package.value ? this.item.package.value.products : []
      return products.map(i => i.code)
    }
  },
  methods: {
    get_product_icon (product) {
      switch (product) {
        case 'flight': {
          return 'flight'
        }
        case 'hotel': {
          return 'hotel'
        }
        case 'tour': {
          return 'tour'
        }
        case 'transfer': {
          return 'emoji_transportation'
        }
        case 'tour_package': {
          return 'hiking' 
        }
        case 'car_rental': {
          return 'car_rental'
        }
        default: 'flight'
      }
    },
    select () {
      this.$emit('on_show_modal', this.item)
    }
  }
}
</script>