import Service from './base'

class StorageService extends Service {
  BASE_ENDPOINT = ''

  list_regions (payload) {
    let request = {
      method: 'get',
      payload
    }
    return this.do_request(this.BASE_ENDPOINT + '/region/list', request)
  }

  list_accommodation (payload) {
     let request = {
      method: 'get',
      payload
    }

    return this.do_request(this.BASE_ENDPOINT + '/accommodation/list', request)
  }

  list_destinations (payload) {
    let request = {
      method: 'get',
      payload
    }
    return this.do_request(this.BASE_ENDPOINT + '/destination/list', request)
  }


  list_places (payload) {
    let request = {
      method: 'get',
      payload
    }
    return this.do_request(this.BASE_ENDPOINT + '/places', request)
  }
}

export default StorageService
