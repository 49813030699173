<template>
  <div class="gq-search-form__content">
    <q-form ref="form" greedy @submit="$emit('submit')" class="q-row q-col-gutter-sm text-capitalize">
      <div class="q-col-12 q-col-md-9">
        <div class="q-row q-col-gutter-sm">
          <div class="q-col-12 q-col-md-6">
            <location-picker :form="value" :languages="languages" />
          </div>
          <div class="q-col-12 q-col-md-6">
            <pax-picker :label="passengers_label"
                        prepend_icon="group"
                        :languages="languages"
                        :child_age="context.age_policy.child_age"
                        :infant_age="context.age_policy.infant_age"
                        :traveler_types="configs ? configs.traveler_types : []"
                        :max_adults="traveler_configs.max_adults"
                        :max_children="traveler_configs.max_children"
                        :max_infants="traveler_configs.max_infants"
                        :total_traveler_limit="traveler_configs.total_traveler_limit"
                        :traveler_limit_type="traveler_configs.traveler_limit_type"
                        v-model="value.paxes" dense outlined/>
          </div>
          <div class="q-col-12 q-col-md-6">
            <div class="q-row q-col-gutter-sm">
              <div class="q-col-6">
                <date-picker
                  outlined
                  dense
                  prepend_icon="event"
                  v-model="value.start_date"
                  :label="pickup_date_label"
                />
              </div>
              <div class="q-col-6">
                <time-picker
                  outlined
                  dense
                  v-model="value.start_time"
                  :label="pickup_time_label"
                />
              </div>
            </div>
          </div>
          <div  class="q-col-12 q-col-md-6" 
                v-if="value.route_type === 'round-trip' && value.is_transfer_from_airport">
            <div class="q-row q-col-gutter-sm">
              <div class="q-col-6">
                <date-picker
                  outlined
                  dense
                  v-model="value.end_date"
                  prepend_icon="event"
                  :label="return_date_label"
                />
              </div>
              <div class="q-col-6">
                <time-picker
                  outlined
                  dense
                  v-model="value.end_time"
                  :label="return_time_label"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="q-col-12 q-col-md-3">
        <div class="q-row q-col-gutter-sm">
          <div class="q-col-12" :class="value.is_transfer_from_airport ? ['q-col-md-6'] : []" v-if="value.is_transfer_from_airport">
            <div class="q-row items-center" style="height: 40px">
              <q-toggle dense @click.native="toggle_route_type"
                        :value="value.route_type === 'round-trip'"
                        :label="round_trip_label"/>
            </div>
          </div>
          <div class="q-col-12" :class="value.is_transfer_from_airport ? ['q-col-md-6'] : []">
            <search-btn :height="40" :languages="languages" />
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>

import LocationPicker from '../../helpers/location-picker'
import SearchBtn from '../../helpers/search-btn'
import PaxPicker from '../../helpers/pax-picker'
import DatePicker from '../../helpers/date-picker'
import TimePicker from '../../helpers/time-picker'

export default {
  components: {
    DatePicker,
    TimePicker,
    SearchBtn,
    PaxPicker,
    LocationPicker,
  },
  props: {
    context: Object,
    frontend_url: String,
    value: Object,
    languages: {type: Object, default: () => ({})},
    configs: {type: Object},
    traveler_configs: {type: Object, default: () => ({})}
  },
  data () {
    return {
      rules: {
        start_date: [
          v => !!v || ''
        ],
        end_date: [
          v => !!v || ''
        ],
        start_time: [
          v => !!v || ''
        ],
        end_time: [
          v => !!v || ''
        ],
        paxes: [],
      },
      route_types: [
        {
          id: 'round-trip',
          label: this.round_trip_label
        },
        {
          id: 'one-way',
          label: this.one_way_label
        }
      ],
    }
  },
  computed: {
    passengers_label () {
      return this.languages['transfer.passengers'] || 'transfer.passengers'
    },
    round_trip_label () {
      return this.languages['transfer.round-trip'] || 'transfer.round-trip'
    },
    one_way_label () {
      return this.languages['transfer.one-way'] || 'transfer.one-way'
    },
    pickup_date_label () {
      return this.languages['transfer.pickup-date'] || 'transfer.pickup-date'
    },
    pickup_time_label () {
      return this.languages['transfer.pickup-time'] || 'transfer.pickup-time'
    },
    return_date_label () {
      return this.languages['transfer.return-date'] || 'transfer.return-date'
    },
    return_time_label () {
      return this.languages['transfer.return-time'] || 'transfer.return-time'
    }

  },
  methods: {
    handle_date_changes (val) {
      this.value.start_date = val.from
      this.value.end_date = val.to
    },
    toggle_route_type () {
      let value = this.value.route_type
      this.value.route_type = value === 'round-trip' ? 'one-way' : 'round-trip'
    }
  },
}
</script>