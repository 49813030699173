<template>
  <div class="gq-search-form">
    <div class="gq-search-form__content">
      <div class="q-row q-col-gutter-md">
        <div v-for="item of quick_search" :key="item.id" class="q-col-md-3 q-col-sm-6 q-col-12">
          <quick-search-item  :item="item.props"
                              :context="context" 
                              @on_show_modal="on_show_modal" />
        </div>
      </div>
    </div>

    <q-dialog v-model="show_modal" :maximized="$q.screen.name === 'xs'" content-style="max-width: 100vw">
      <q-card style="max-width: 1200px; width: 100%">
        <q-card-section class="q-row items-center q-pb-none">
          <div class="text-h6">{{ title_modal }}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-separator class="q-mt-md" />

        <div style="width: 100%">
          <search-form  :context="context"
                        :frontend_url="frontend_url"
                        :selected_package="selected_package"
                        :quick_search_to="quick_search_to" />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import QuickSearchItem from './item'
import SearchForm from '../forms'

export default {
  components: {
    QuickSearchItem,
    SearchForm
  },
  props: {
    context: Object,
    frontend_url: String,
  },
  data () {
    return {
      show_modal: false,
      selected_package: {},
      quick_search_to: {},
      quick_search:  [
        {
          id: 1,
          props: {
            region: {
              value: {
                name: 'Taipei'
              }
            },
            description: {
              value: {
                'en-US': 'Per person return'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'flight' }
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/290386/pexels-photo-290386.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 595
            },
            price_from: {
              value: '245'
            }
          }
        },
        {
          id: 2,
          props: {
            region: {
              value: {
                name: 'Phuket'
              }
            },
            description: {
              value: {
                'en-US': 'Per person twin share'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'hotel' }
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/2771807/pexels-photo-2771807.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 234
            },
            price_from: {
              value: '115'
            }
          }
        },
        {
          id: 3,
          props: {
            region: {
              value: {
                name: 'Hong Kong'
              }
            },
            description: {
              value: {
                'en-US': 'Price per person'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'hotel' }, { code: 'flight' }
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/3405489/pexels-photo-3405489.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 552
            },
            price_from: {
              value: '142'
            }
          }
        },
        {
          id: 4,
          props: {
            region: {
              value: {
                name: 'Hanoi'
              }
            },
            description: {
              value: {
                'en-US': 'Price per person'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'tour' }
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/3405489/pexels-photo-3405489.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 552
            },
            price_from: {
              value: '142'
            }
          }
        },
        {
          id: 5,
          props: {
            region: {
              value: {
                name: 'Singapore'
              }
            },
            description: {
              value: {
                'en-US': 'Price per person'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'tour_package' },
                  { code: 'flight' },
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/3405489/pexels-photo-3405489.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 552
            },
            price_from: {
              value: '142'
            }
          }
        },
        {
          id: 12,
          props: {
            region: {
              value: {
                name: 'Singapore'
              }
            },
            description: {
              value: {
                'en-US': 'Price per person'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'transfer' },
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/3405489/pexels-photo-3405489.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 552
            },
            price_from: {
              value: '142'
            }
          }
        },
        {
          id: 6,
          props: {
            region: {
              value: {
                name: 'Phuket'
              }
            },
            description: {
              value: {
                'en-US': 'Per person twin share'
              },
            },
            package: {
              value: {
                products: [
                  { code: 'car_rental' }
                ]
              }
            },
            avatar: {
              value: {
                url: 'https://images.pexels.com/photos/2771807/pexels-photo-2771807.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              }
            },
            height: {
              value: 200
            },
            hotel_count: {
              value: 234
            },
            price_from: {
              value: '115'
            }
          }
        },
      ]
    }
  },
  computed: {
    title_modal() {
      let result = 'Quick Search '
      if (this.find_package(['flight'])) {
        result = result + 'Flight'
      }
      if (this.find_package(['hotel'])) {
        result = result + 'Hotel'
      }
      if (this.find_package(['flight', 'hotel'])) {
        result = result + 'Flight + Hotel'
      }
      if (this.find_package(['tour'])) {
        result = result + 'Tour'
      }
      if (this.find_package(['transfer'])) {
        result = result + 'Transfer'
      }
      if (this.find_package(['tour_package'])) {
        result = result + 'Tour Package'
      }
      if (this.find_package(['flight', 'tour_package'])) {
        result = result + 'Tour Package + Flight'
      }
      if (this.find_package(['car_rental'])) {
        result = result + 'Car Rental'
      }
      return result
    }
  },
  methods: {
    find_package (products) {
      if (!this.selected_package.products) return false

      const product_sort = this.selected_package.products.map(p => p.code).sort()
      return JSON.stringify(product_sort) === JSON.stringify(products.sort())
    },

    on_show_modal (item) {
      this.show_modal = true
      this.selected_package = item.package ? item.package.value : {}
      this.quick_search_to = item.region ? item.region.value : null
    }
  }
}
</script>