import install from './install.js'
import lang from './lang.js'
import iconSet from './icon-set.js'
import ssrUpdate from './ssr-update.js'
import {VERSION as version} from './constants'

export default {
  version,
  install,
  lang,
  iconSet,
  ssrUpdate
}
