<template>
  <q-field :value="value"
           :label="label"
           :disable="disabled"
           :readonly="readonly"
           hide-bottom-space
           :rules="rules"
           :clearable="clearable"
           :dense="dense"
           :outlined="outlined"
           style="cursor: pointer"
  >
    <template v-slot:control>
      <q-item-label lines="1">{{ display_value }}</q-item-label>
    </template>
    <template v-slot:append v-if="append_icon">
      <q-icon :name="append_icon"/>
    </template>
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon"/>
    </template>
    <q-popup-proxy ref="popup_proxy"
                   v-if="!readonly && !disabled"
                   transition-show="scale" transition-hide="scale">
      <div style="max-width: 300px">
        <q-time
            @input="handle_date_changed"
            :value="picker_value"
            format24h
        />
      </div>
    </q-popup-proxy>
  </q-field>
</template>
<script>
import {convert_datetime_format, convert_string_to_date} from '../../../utils'

export default {
  props: {
    value: {type: String},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    outlined: {type: Boolean, default: false},
    only_future: {type: Boolean, default: false},
    limit_date_func: {type: Function},
    label: {type: String},
    prepend_icon: {type: String, default: 'schedule'},
    append_icon: {type: String},
    rules: {type: Array},
    time_format: {type: String, default: 'HH:MM'}
  },
  data () {
    return {}
  },
  methods: {
    convert_datetime_format,
    handle_date_changed (val) {
      this.$emit('input', val)
      this.$refs.popup_proxy.hide()
    }
  },
  computed: {
    display_format () {
      return 'HH:mm'
    },
    display_value () {
      if (!this.value) return null
      let val = convert_string_to_date(`2000-01-01T${this.value}:00`, 'iso')
      return val.format(this.display_format)
    },
    picker_value () {
      return this.value
    }
  }
}
</script>
