import Vue from 'vue'

import DarkMixin from '../../mixins/dark.js'
import ListenersMixin from '../../mixins/listeners.js'

import { slot } from '../../utils/slot.js'

const attrs = { role: 'alert' }

export default Vue.extend({
  name: 'QBanner',

  mixins: [ ListenersMixin, DarkMixin ],

  props: {
    inlineActions: Boolean,
    dense: Boolean,
    rounded: Boolean
  },

  render (h) {
    const actions = slot(this, 'action')
    const child = [
      h('div', {
        staticClass: 'q-banner__avatar q-col-auto q-row items-center self-start'
      }, slot(this, 'avatar')),

      h('div', {
        staticClass: 'q-banner__content q-col text-body2'
      }, slot(this, 'default'))
    ]

    actions !== void 0 && child.push(
      h('div', {
        staticClass: 'q-banner__actions q-row items-center justify-end',
        class: `q-col-${this.inlineActions === true ? 'auto' : 'all'}`
      }, actions)
    )

    return h('div', {
      staticClass: 'q-banner q-row items-center',
      class: {
        'q-banner--top-padding': actions !== void 0 && !this.inlineActions,
        'q-banner--dense': this.dense,
        'q-banner--dark q-dark': this.isDark,
        'rounded-borders': this.rounded
      },
      attrs,
      on: { ...this.qListeners }
    }, child)
  }
})
