<template>
  <div class="gq-search-form__content">
    <q-form ref="form" greedy
            @submit="$emit('submit')"
            class="q-row q-col-gutter-sm">
      <div class="q-col-12 q-col-md-3">
        <div class="q-row q-col-gutter-sm">
          <div class="q-col-12">
            <region-select :label="pickup_location_label" outlined
                          v-model="value.origin" dense
                          :rules="rules.origin"
                          :types="['airport']"
                          prepend_icon="place"/>
          </div>
          <div class="q-col-12">
            <different-dropoff-location v-model="value.diff_dropoff_location" 
                                        :languages="languages"
                                        :form="value" />
          </div>
        </div>
      </div>
      <div class="q-col-12 q-col-md-3">
        <div class="q-row q-col-gutter-sm">
          <div class="q-col-12">
            <date-picker prepend_icon="event" dense
                        :rules="rules.start_date"
                        v-model="value.start_date"
                        outlined only_future
                        :label="pickup_date_label"/>
          </div>
          <div class="q-col-12">
            <date-picker prepend_icon="event" dense
                        v-model="value.end_date"
                        :rules="rules.end_date"
                        outlined only_future
                        :label="dropoff_date_label"/>
          </div>
        </div>
      </div>
      <div class="q-col-12 q-col-md-3">
        <div class="q-row q-col-gutter-sm">
          <div class="q-col-12">
            <time-picker v-model="value.start_time"
                        :rules="rules.start_time" dense
                        :label="pickup_time_label" outlined/>
          </div>
          <div class="q-col-12">
            <time-picker :label="dropoff_time_label" dense
                        v-model="value.end_time"
                        :rules="rules.end_time"
                        outlined/>
          </div>
        </div>
      </div>
      <div class="q-col-12 q-col-md-3">
        <div class="q-row q-col-gutter-sm">
          <div class="q-col-12">
            <q-input type="number" min="18"
                    v-model="value.driver_age"
                    hide-bottom-space dense
                    :rules="rules.driver_age"
                    outlined :label="driver_age">
              <template v-slot:prepend>
                <q-icon name="person"/>
              </template>
            </q-input>
          </div>
          <div class="q-col-12">
            <search-btn :height="40" :languages="languages" />
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>

import SearchBtn from '../../helpers/search-btn'
import RegionSelect from '../../helpers/region-select'
import DatePicker from '../../helpers/date-picker'
import TimePicker from '../../helpers/time-picker'
import DifferentDropoffLocation from '../../helpers/different-dropoff-location'

export default {
  components: {
    RegionSelect,
    DatePicker,
    SearchBtn,
    TimePicker,
    DifferentDropoffLocation,
  },
  props: {
    context: Object,
    frontend_url: String,
    value: Object,
    languages: {type: Object, default: () => ({})},
  },
  data () {
    return {
      rules: {
        driver_age: [
          v => !!v || '',
          v => v >= 18 || ''
        ],
        origin: [
          v => !!v || ''
        ],
        start_date: [
          v => !!v || ''
        ],
        end_date: [
          v => !!v || ''
        ],
        start_time: [
          v => !!v || ''
        ],
        end_time: [
          v => !!v || ''
        ],
        paxes: [],
      }
    }
  },
  computed: {
    pickup_location_label () {
      return this.languages['car-rental.pickup-location'] || 'car-rental.pickup-location'
    },
    pickup_date_label () {
      return this.languages['car-rental.pickup-date'] || 'car-rental.pickup-date'
    },
    dropoff_date_label () {
      return this.languages['car-rental.dropoff-date'] || 'car-rental.dropoff-date'
    },
    pickup_time_label () {
      return this.languages['car-rental.pickup-time'] || 'car-rental.pickup-time'
    },
    dropoff_time_label () {
      return this.languages['car-rental.dropoff-time'] || 'car-rental.dropoff-time'
    },
    driver_age () {
      return this.languages['car-rental.driver-age'] || 'car-rental.driver-age'
    }
  }
}
</script>