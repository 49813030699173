<template>
  <div class="gq-search-form__content">
    <q-form ref="form" greedy
            @submit="$emit('submit')"
            class="q-row q-col-gutter-sm">
      <div class="q-col-12 q-col-md-4">
        <region-select :label="destination_label" outlined dense
                      v-model="value.destination"
                      :rules="rules.destination"
                      :types="['multi_city_vicinity', 'city', 'airport']"
                      expedia_id_exists property_included
                      prepend_icon="place"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <date-picker prepend_icon="event" dense
                    outlined range only_future
                    :rules="rules.date_range"
                    @input="handle_date_changes"
                    :value="{from: value.start_date, to: value.end_date}"
                    :label="checkin_checkout_label"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <pax-picker with_rooms outlined dense
                    :languages="languages"
                    v-model="value.paxes"
                    prepend_icon="people"
                    :child_age="context.age_policy.child_age"
                    :infant_age="context.age_policy.infant_age"
                    :traveler_types="configs ? configs.traveler_types : []"
                    :max_adults="traveler_configs.max_adults"
                    :max_children="traveler_configs.max_children"
                    :max_infants="traveler_configs.max_infants"
                    :total_traveler_limit="traveler_configs.total_traveler_limit"
                    :traveler_limit_type="traveler_configs.traveler_limit_type"
                    :label="passengers_label"/>
      </div>
      <div class="q-col-12 q-col-md-4" :class="$q.screen.name !== 'xs' ? 'offset-8' : ''">
        <search-btn :languages="languages" />
      </div>
    </q-form>
  </div>
</template>

<script>
import RegionSelect from '../../helpers/region-select'
import SearchBtn from '../../helpers/search-btn'
import PaxPicker from '../../helpers/pax-picker'
import DatePicker from '../../helpers/date-picker'

export default {
  components: {
    RegionSelect,
    SearchBtn,
    PaxPicker,
    DatePicker
  },
  props: {
    context: Object,
    frontend_url: String,
    value: Object,
    languages: {type: Object, default: () => ({})},
    configs: {type: Object},
    traveler_configs: {type: Object, default: () => ({})}
  },
  data () {
    return {
      rules: {
        destination: [
          v => !!v || ''
        ],
        date_range: [
          v => (!!v.from && !!v.to) || ''
        ],
        paxes: [],
      }
    }
  },
  computed: {
    destination_label () {
      return this.languages['hotel.destination'] || 'hotel.destination'
    },
    checkin_checkout_label () {
      const check_in = this.languages['hotel.checkin'] || 'hotel.checkin'
      const check_out = this.languages['hotel.checkout'] || 'hotel.checkout'
      return `${check_in} / ${check_out}`
    },
    passengers_label () {
      return this.languages['hotel.passengers'] || 'hotel.passengers'
    },
  },
  methods: {
    handle_date_changes (val) {
      this.value.start_date = val.from
      this.value.end_date = val.to
    }
  }
}
</script>