<template>
  <q-select v-bind="v_bind"
            ref="input"
            @filter="filter"
            @input="handle_value_changed">
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon"/>
    </template>
    <template v-slot:append v-if="append_icon">
      <q-icon :name="append_icon"/>
    </template>
    <template v-slot:option="scope">
      <q-item
        v-bind="scope.itemProps"
        v-on="scope.itemEvents"
      >
        <q-item-section>
          <template v-if="scope.opt.type === 'property'">
            <q-item-label v-html="scope.opt.name" lines="1"/>
            <q-item-label caption v-if="scope.opt.street_address"
                          lines="1"
                          v-html="scope.opt.street_address"/>
          </template>
          <template v-else-if="scope.opt.type === 'tour'">
            <q-item-label v-html="scope.opt.title" lines="1"/>
          </template>
          <template v-else>
            <q-item-label v-html="scope.opt.name" lines="1"/>
            <q-item-label caption v-if="scope.opt.long_name"
                          lines="1"
                          v-html="scope.opt.long_name"/>
          </template>

        </q-item-section>
        <q-item-section avatar>
          <q-icon name="flight" v-if="scope.opt.type === 'airport'"/>
          <q-icon name="hotel" v-else-if="scope.opt.type === 'property'"/>
          <q-icon name="tour" v-else-if="scope.opt.type === 'tour'"/>
          <q-icon name="place" v-else/>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script>
export default {
  props: {
    value: {type: Object},
    outlined: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    fetch_on_click: {type: Boolean, default: false},
    expedia_id_exists: {type: Boolean},
    viator_id_exists: {type: Boolean},
    property_included: {type: Boolean},
    tour_included: {type: Boolean},
    label: {type: String},
    append_icon: {type: String},
    prepend_icon: {type: String},
    rules: {type: Array, default: () => ([])},
    types: {type: Array, default: () => ([])},
    ancestor_id: {type: String},
    association_id: {type: String},
    static_route_enabled: {type: Boolean},
    destination_type: {type: String},
    departure_code: {type: String},
    arrival_code: {type: String},
  },
  data () {
    return {
      options: []
    }
  },
  computed: {
    v_bind () {
      return {
        outlined: this.outlined,
        dense: this.dense,
        label: this.label,
        value: this.value,
        options: this.options,
        rules: this.rules,
        'use-input': true,
        'hide-bottom-space': true,
        'fill-input': true,
        'hide-selected': true,
        'input-debounce': 500,
        'option-label': 'name',
        'option-value': 'id',
        clearable: this.clearable
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.input.validate()
    },
    async filter (search_text, update, abort) {
      if (!search_text && this.options.length && !this.fetch_on_click) {
        return update()
      }
      this.options = await this.list(search_text)
      update()
    },
    async list (search_text) {
      const service = new this.$services.StorageService(this)
      let payload = {
        language_code: 'en-US',
        search_text: search_text,
        per_page: 20,
      }
      if (this.expedia_id_exists) payload.expedia_id_exists = true
      if (this.viator_id_exists) payload.viator_id_exists = true
      if (this.property_included) payload.property_included = true
      if (this.tour_included) payload.tour_included = true
      if (this.types) {
        payload.types = this.types.join(',')
      }
      if (this.ancestor_id) {
        payload.ancestor_id = this.ancestor_id
      }
      if (this.association_id) {
        payload.association_id = this.association_id
      }
          if (this.destination_type) {
        payload.destination_type = this.destination_type
      }
      if (this.departure_code) {
        payload.departure_code = this.departure_code
      }
      if (this.arrival_code) {
        payload.arrival_code = this.arrival_code
      }

      const response = await service.list_places(payload)
      if (response.status === 200) {
        let result = response.data.places
        if (this.property_included) {
          result = result.concat(response.data.properties.map(p => {
            p.type = 'property'
            return p
          }))
        }
        if (this.tour_included) {
          result = result.concat(response.data.tours.map(t => {
            t.type = 'tour'
            return t
          }))
        }
        return result
      }
      return []
    },
    handle_value_changed (val) {
      let value = null
      if (val) {
        value = {
          id: val.id,
          name: val.name,
          type: val.type,
          code: val.code,
          long_name: val.long_name,
          location: val.location,
          ancestors: val.ancestors,
          nearest_airports: val.nearest_airports
        }
      }
      this.$emit('input', value)
    },
  },
}
</script>
