<template>
  <q-field outlined :label-slot="value" dense
           hide-bottom-space :rules="rules"
           :value="display_value"
           :stack-label="value">
    <template v-slot:label v-if="value">{{checkin_checkout_label}}</template>
    <template v-slot:prepend>
      <q-icon class="cursor-pointer"
              @click="$emit('input', !value)"
              :name="value ? 'check_box': 'check_box_outline_blank'"/>
    </template>
    <template v-if="value" v-slot:control>
      <q-item-label lines="1">{{ display_value }}</q-item-label>
    </template>
    <template v-else v-slot:control>
      <q-item-label @click="$emit('input', !value)"
                    class="cursor-pointer">
          {{ separate_checkbox_label }}
      </q-item-label>
    </template>
    <q-popup-proxy ref="popup_proxy"
                   v-if="value"
                   transition-show="scale" transition-hide="scale">
      <div style="max-width: 300px">
        <q-date :value="picker_value"
                minimal range
                :default-year-month="start_date ? start_date.format('YYYY/MM') : null"
                :mask="display_format"
                :options="validate_date"
                @input="handle_date_changed"/>
      </div>
    </q-popup-proxy>
  </q-field>
</template>
<script>
import {convert_string_to_date, convert_datetime_format, get_now} from '../../../utils'

export default {
  props: {
    value: {type: Boolean, required: true},
    form: {type: Object, required: true},
    date_format: {type: String, default: 'YYYY-MM-DD'},
    languages: {type: Object, default: () => ({})},
  },
  data () {
    return {
      rules: [
          v => {
            if (!this.value) return true
            return v !== 'Invalid date - Invalid date' || ''
          }
      ]
    }
  },
  methods: {
    clear () {
      this.form.checkin = null
      this.form.checkout = null
    },
    convert_datetime_format,
    handle_date_changed (val) {
      if (!val) return this.clear()
      this.form.checkin = convert_datetime_format(val.from, this.display_format, this.date_format)
      this.form.checkout = convert_datetime_format(val.to, this.display_format, this.date_format)
      this.$refs.popup_proxy.hide()
    },
    validate_date (date) {
      let quasar_format = 'YYYY/MM/DD'
      let start_date = convert_datetime_format(this.form.start_date,
          'YYYY-MM-DD',
          quasar_format)
      let end_date = convert_datetime_format(this.form.end_date,
          'YYYY-MM-DD',
          quasar_format)
      return (start_date <= date) && (date <= end_date)
    }
  },
  computed: {
    checkin_checkout_label () {
      const check_in = this.languages['bundle.checkin'] || 'bundle.checkin'
      const check_out = this.languages['bundle.checkout'] || 'bundle.checkout'
      return `${check_in} / ${check_out}`
    },
    separate_checkbox_label () {
      return this.languages['bundle.separate-check-inout'] || 'bundle.separate-check-inout'
    },
    start_date () {
      if (!this.form.start_date) return null
      return convert_string_to_date(this.form.start_date, 'YYYY-MM-DD')
    },
    display_format () {
      return 'DD/MM/YYYY'
    },
    display_value () {
      let start = convert_datetime_format(this.form.checkin, this.date_format, this.display_format)
      let end = convert_datetime_format(this.form.checkout, this.date_format, this.display_format)
      return `${start} - ${end}`
    },
    picker_value () {
      return {
        from: convert_datetime_format(this.form.checkin, this.date_format, this.display_format),
        to: convert_datetime_format(this.form.checkout, this.date_format, this.display_format)
      }
    }
  },
  watch: {
    'form.start_date' (val) {
      let end_date = this.form.end_date
      let checkin = this.form.checkin
      if (!val) this.form.checkin = null
      if (checkin) {
        if (checkin < val) this.form.checkin = val
        if (end_date && checkin >= end_date) this.form.checkin = val
      }
      else this.form.checkin = val
    },
    'form.end_date' (val) {
      let start_date = this.form.start_date
      let checkout = this.form.checkout
      if (!val) this.form.checkout = null
      if (checkout) {
        if (checkout > val) this.form.checkout = val
        if (start_date && checkout <= start_date) this.form.checkout = val
      }
      else this.form.checkout = val
    },
  }
}
</script>
