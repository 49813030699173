<template>
  <div class="q-row q-col-gutter-sm text-caption text-capitalize">
    <div :class="col_class" v-if="has_type('adult')">
      <label>{{ adults_label }} <span class="text-caption text-grey">({{ adult_age_range }})</span></label>
      <q-select outlined dense
                type="number" options-dense
                :options="adult_options"
                min="1"
                @input="emit_changes"
                :max="max_adults"
                v-model="value.adult"
                class="pax-input">
        <!-- <template v-slot:hint>
          <q-item-label caption>{{ maximum_label }}: {{ max_adults }}</q-item-label>
        </template> -->
      </q-select>
    </div>
    <div :class="col_class" v-if="has_type('child')">
      <label>{{ children_label }} <span class="text-caption text-grey">({{ child_age_range }})</span></label>
      <q-select outlined dense
                type="number" options-dense
                min="0"
                :max="max_children"
                @input="emit_changes"
                :options="child_options"
                v-model="value.child"
                hide-bottom-space
                class="pax-input">
        <!-- <template v-slot:hint>
          <q-item-label caption>{{ maximum_label }}: {{ max_children }}</q-item-label>
        </template> -->
      </q-select>
    </div>
    <div :class="col_class" v-if="has_type('infant')">
      <label>{{ infants_label }} <span class="text-caption text-grey">({{ infant_age_range }})</span></label>
      <q-select outlined dense options-dense
                type="number"
                :options="infant_options"
                min="0"
                :max="max_infants"
                @input="emit_changes"
                v-model="value.infant"
                hide-bottom-space
                class="pax-input">
        <!-- <template v-slot:hint>
          <q-item-label caption>{{ maximum_label }}: {{ max_infants }}</q-item-label>
        </template> -->
      </q-select>
    </div>
    <div class="q-col-12" v-if="ages.filter(i => i.pax_type === 'child').length > 0">
      <div class="q-row q-col-gutter-sm">
        <div  v-for="(item, index) in ages.filter(i => i.pax_type === 'child')"
              :key="index"
              class="q-col-4">
          <label class="caption">
            {{ child_age_label }} #{{ index + 1 }}
          </label>
          <q-select
            outlined
            dense
            options-dense
            :options="Array.from({ length: (child_age + 1) - infant_age }, (_, i) => i + infant_age)"
            v-model="item.value"
            class="pax-input"
            hide-bottom-space
          />
        </div>
      </div>
    </div>
    <div class="q-col-12" v-if="ages.filter(i => i.pax_type === 'infant').length > 0">
      <div class="q-row q-col-gutter-sm">
        <div  v-for="(item, index) in ages.filter(i => i.pax_type === 'infant')"
              :key="index"
              class="q-col-4">
          <label class="caption">
            {{ infant_age_label }} #{{ index + 1 }}
          </label>
          <q-select
            outlined
            dense
            options-dense
            :options="Array.from(Array(infant_age).keys())"
            v-model="item.value"
            class="pax-input"
            style="padding-bottom: 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    infant_age: {type: Number, default: 2},
    child_age: {type: Number, default: 17},
    max_adults: {type: Number, default: 9},
    max_children: {type: Number, default: 6},
    max_infants: {type: Number, default: 3},
    traveler_limit_type: {type: String, default: 'per_type'},
    total_traveler_limit: {type: Number, default: 9},
    value: {type: Object, required: true},
    ages: {type: Array},
    no_infant: {type: Boolean},
    languages: {type: Object, default: () => ({})},
    adults_label: String,
    children_label: String,
    infants_label: String,
    traveler_types: {type: Array, default: () => ([])}
  },
  computed: {
    adult_age_range () {
      return `${this.child_age + 1}+`
    },
    infant_age_range () {
      return `Under ${this.infant_age}`
    },
    child_age_range () {
      return `${this.infant_age} - ${this.child_age}`
    },
    maximum_label () {
      return this.languages['common.maximum'] || 'Maximum'
    },
    child_age_label () {
      return this.languages['common.child-age'] || 'Child Age'
    },
    infant_age_label () {
      return this.languages['common.infant-age'] || 'Infant Age'
    },
    col_class () {
      if (this.traveler_types.length === 1) {
        return 'q-col-12'
      } else if (this.traveler_types.length === 2) {
        return 'q-col-6'
      }
      return 'q-col-4'
    },
    infant_options () {
      let result = this.max_infants
      if (this.traveler_limit_type === 'by_total') {
        const { adult, child } = this.value || {}
        result = this.total_traveler_limit - (adult + child)
      }
      result = result > 0 ? result : 0
      return Array.from(Array(result + 1).keys())
    },
    child_options () {
      let result = this.max_children
      if (this.traveler_limit_type === 'by_total') {
        const { adult, infant } = this.value || {}
        result = this.total_traveler_limit - (adult + infant)
      }
      result = result > 0 ? result : 0
      return Array.from(Array(result + 1).keys())
    },
    adult_options () {
      let result = this.max_adults
      if (this.traveler_limit_type === 'by_total') {
        const { child, infant } = this.value || {}
        result = this.total_traveler_limit - (child + infant)
      }
      result = result > 0 ? result : 0
      return Array.from({length: result}, (_, i) => i + 1)
    }
  },
  methods: {
    emit_changes () {
      this.$emit('input', this.value)
    },
    has_type (type) {
      if (this.traveler_types.length > 0 && !this.traveler_types.find(i => i === type)) {
        return false
      }
      return true
    }
  }
}
</script>