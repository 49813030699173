<template>
  <div class="q-row q-col-gutter-sm text-capitalize" style="position: relative">
    <div class="q-col-12 q-col-md-6">
      <region-select
        outlined
        dense fetch_on_click
        :rules="rules.origin"
        v-model="form.origin"
        v-bind="origin_bind"
      />
    </div>
    <div class="q-col-12 q-col-md-6">
      <region-select
        outlined
        dense fetch_on_click
        :rules="rules.destination"
        v-model="form.destination"
        v-bind="destination_bind"
      />
    </div>
    <q-btn
      round
      color="white"
      text-color="primary"
      class="bg-white swap-transfer-route-btn"
      :icon="$q.screen.name === 'xs' ? 'swap_vert' : 'swap_horiz'"
      outline
      @click="form.is_transfer_from_airport = !form.is_transfer_from_airport"
      dense
    ></q-btn>
  </div>
</template>
<script>
import RegionSelect from '../region-select'

export default {
  components: {
    RegionSelect
  },
  props: {
    form: {type: Object, required: true},
    languages: {type: Object, default: () => ({})}
  },
  data () {
    return {
      rules: {
        origin: [
          v => !!v || ''
        ],
        destination: [
          v => !!v || ''
        ],
      },
      airport_label: this.languages['transfer.airport'] || 'transfer.airport',
      destination_label: this.languages['transfer.destination'] || 'transfer.destination',
    }
  },
  computed: {
    origin_bind () {
      let result = {}
      let label = this.airport_label
      let prepend_icon = 'flight'
      let types = ['airport']
      if (!this.form.is_transfer_from_airport) {
        label = this.destination_label
        prepend_icon = 'place'
        result['property_included'] = true
        types = []
      }
      result.label = label
      result.prepend_icon = prepend_icon
      result.types = types
      return result
    },
    destination_bind () {
      let result = {}
      let label = this.airport_label
      let prepend_icon = 'flight'
      let types = ['airport']
      if (this.form.is_transfer_from_airport) {
        label = this.destination_label
        prepend_icon = 'place'
        result['property_included'] = true
        types = []
        if (this.form.origin) {
          let ancestor = this.form.origin.ancestors.find(a => a.type === 'multi_city_vicinity')
          if (ancestor) {
            result.ancestor_id = ancestor.id
          } else {
            result.association_id = this.form.origin.id
          }
        }
      }
      result.label = label
      result.prepend_icon = prepend_icon
      result.types = types

      return result
    }
  },
  methods: {},
  watch: {
    'form.is_transfer_from_airport' () {
      this.form.origin = null
      this.form.destination = null
    }
  }
}
</script>
<style scoped>
.swap-transfer-route-btn {
  position: absolute;
  right: calc(50% - 16px - 4px);
  top: calc(50% - 16px + 4px);
  padding: 0;
  width: 32px;
  height: 32px;
}
</style>
