<template>
  <q-btn class="q-full-width" color="primary"
         unelevated
         type="submit"
         :style="styles">
    <span>{{ languages['components.shopping.forms.common.search-btn.label'] || 'Search' }}</span>
  </q-btn>
</template>
<script>
export default {
  props: {
    height: {type: Number, default: 40},
    languages: {type: Object, default: () => ({})}
  },
  computed: {
    styles () {
      return {
        height: `${this.height}px`
      }
    }
  }
}
</script>
