<template>
  <q-field :value="value"
           :label="label"
           :disable="disabled"
           :readonly="readonly"
           hide-bottom-space
           :rules="rules"
           :clearable="clearable"
           :dense="dense"
           :outlined="outlined"
           style="cursor: pointer"
           @click.native="show_picker"
           class="text-capitalize"
  >
    <template v-slot:control>
      <q-item-label lines="1">{{ display_value }}</q-item-label>
    </template>
    <template v-slot:append v-if="append_icon">
      <q-icon :name="append_icon"/>
    </template>
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon"/>
    </template>
    <q-popup-proxy  ref="popup_proxy"
                   v-if="!readonly && !disabled" no-parent-event
                   transition-show="scale" transition-hide="scale">
      <div style="max-width: 300px">
        <q-date v-model="value_holder"
                v-bind="v_bind" flat
                :mask="date_format"
                @input="handle_date_changed"
                :options="validate_date"/>
      </div>
    </q-popup-proxy>
  </q-field>
</template>
<script>
import {convert_datetime_format, get_now, convert_date_to_string} from '../../../utils'
import cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    value: {type: [String, Object]},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    outlined: {type: Boolean, default: false},
    range: {type: Boolean, default: false},
    only_future: {type: Boolean, default: true},
    limit_date_func: {type: Function},
    label: {type: String},
    prepend_icon: {type: String},
    append_icon: {type: String},
    font_size_class: {type: String},
    rules: {type: Array},
    date_format: {type: String, default: 'YYYY-MM-DD'},
    default_year: {type: Number},
    default_month: {type: Number},
  },
  data () {
    return {
      value_holder: null,
    }
  },
  methods: {
    convert_datetime_format,
    handle_date_changed (val) {
      if (!val) return
      if (this.range && typeof val !== 'object') return
      this.$emit('input', val)
      this.$refs.popup_proxy.hide()
    },
    validate_date (date) {
      let result = true
      if (this.only_future) {
        let now = convert_date_to_string(get_now(), 'YYYY/MM/DD')
        if (date < now) result = false
      }
      if (this.limit_date_func && !this.limit_date_func(date)) {
        result = false
      }
      return result
    },
    show_picker () {
      this.value_holder = cloneDeep(this.value)
      this.$refs.popup_proxy.show()
    }
  },
  computed: {
    classes () {
      return this.font_size_class
    },
    display_format () {
      return 'DD/MM/YYYY'
    },
    display_value () {
      if (!this.value) return ''
      if (this.range) {
        let start = convert_datetime_format(this.value.from, this.date_format, this.display_format)
        let end = convert_datetime_format(this.value.to, this.date_format, this.display_format)
        return `${start} - ${end}`
      }
      return convert_datetime_format(this.value, this.date_format, this.display_format)
    },
    v_bind () {
      let result = {
        minimal: true,
        range: this.range,
        readonly: this.readonly,
        disabled: this.disabled
      }
      if (this.default_year) {
        let default_month = (this.default_month || 1).toString().padStart(2, '0')
        result['default-year-month'] = `${this.default_year}/${default_month}`
      }

      return result
    }
  }
}
</script>
