<template>
  <div class="gq-search-form__content">
    <q-form ref="form" greedy
            @submit="$emit('submit')"
            class="q-row q-col-gutter-sm eg-form-wrap">
      <div v-if="has_flight" class="q-col-12">
        <route-switch :languages="languages" v-model="value.route_type"/>
      </div>
      <div v-if="has_flight" class="q-col-12 q-col-md-4">
        <region-select :label="origin_label" outlined dense
                      v-model="value.origin"
                      :rules="rules.origin"
                      :types="['airport']"
                      prepend_icon="flight_takeoff"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <region-select :label="destination_label" outlined dense
                      v-model="value.destination"
                      :rules="rules.destination"
                      :types="['airport']"
                      prepend_icon="place"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <date-picker prepend_icon="event"
                      outlined only_future dense
                      :rules="rules.date"
                      v-model="value.start_date"
                      :label="departure_label"/>
      </div>
      <div class="q-col-12 q-col-md-4">
        <pax-picker outlined dense
                    :child_age="context.age_policy.child_age"
                    :infant_age="context.age_policy.infant_age"
                    v-model="value.paxes"
                    prepend_icon="people"
                    :languages="languages"
                    :traveler_types="configs ? configs.traveler_types : []"
                    :max_adults="traveler_configs.max_adults"
                    :max_children="traveler_configs.max_children"
                    :max_infants="traveler_configs.max_infants"
                    :total_traveler_limit="traveler_configs.total_traveler_limit"
                    :traveler_limit_type="traveler_configs.traveler_limit_type"
                    :label="passengers_label"/>
      </div>
      <div v-if="has_flight" class="q-col-12 q-col-md-4">
        <cabin-select with_rooms outlined dense
                      v-model="value.cabin_class"
                      prepend_icon="class"
                      :languages="languages"
                      :cabin_classes="configs ? configs.cabin_classes : []"
                      :label="cabin_class_label"/>
      </div>
      <div class="q-col-12 q-col-md-4" :class="$q.screen.name !== 'xs' && !has_flight ? 'offset-8' : ''">
        <search-btn :languages="languages" />
      </div>
    </q-form>
  </div>
</template>
<script>

import RegionSelect from '../../helpers/region-select'
import SearchBtn from '../../helpers/search-btn'
import PaxPicker from '../../helpers/pax-picker'
import CabinSelect from '../../helpers/cabin-select'
import RouteSwitch from '../../helpers/route-switch'
import DatePicker from '../../helpers/date-picker'

export default {
  components: {
    RegionSelect,
    SearchBtn,
    PaxPicker,
    CabinSelect,
    RouteSwitch,
    DatePicker
  },
  props: {
    context: Object,
    frontend_url: String,
    value: Object,
    has_flight: Boolean,
    languages: {type: Object, default: () => ({})},
    configs: {type: Object},
    traveler_configs: {type: Object, default: () => ({})}
  },
  data() {
    return {
      rules: {
        origin: [
          v => this.has_flight && (!!v || '')
        ],
        destination: [
          v => !!v || ''
        ],
        date: [
          v => !!v || ''
        ],
        paxes: [],
      }
    }
  },
  computed: {
    origin_label () {
      return this.languages['tour-package.origin'] || 'tour-package.origin'
    },
    destination_label () {
      return this.languages['tour-package.destination'] || 'tour-package.destination'
    },
    departure_label () {
      return this.languages['tour-package.departure'] || 'tour-package.departure'
    },
    passengers_label () {
      return this.languages['tour-package.passengers'] || 'tour-package.passengers'
    },
    cabin_class_label () {
      return this.languages['tour-package.cabin-class'] || 'tour-package.cabin-class'
    },
  },
  methods: {
    handle_date_changes(val) {
      this.value.start_date = val.from
      this.value.end_date = val.to
    }
  }
}
</script>
