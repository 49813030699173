<template>
  <div class="gq-search-form" v-if="languages">
    <flight-form  v-if="combine === 'flight'"
                  :configs="get_config('flight')"
                  :traveler_configs="get_traveler_config('flight')"
                  :context="context"
                  :frontend_url="frontend_url"
                  :value="value"
                  :languages="languages"
                  @submit="submit" />

    <hotel-form  v-if="combine === 'hotel'"
                  :configs="get_config('hotel')"
                  :traveler_configs="get_traveler_config('hotel')"
                  :context="context"
                  :frontend_url="frontend_url"
                  :value="value"
                  :languages="languages"
                  @submit="submit" />

    <flight-hotel-form  v-if="combine === 'flight--hotel'"
                        :configs="get_config('flight--hotel')"
                        :traveler_configs="get_traveler_config('flight--hotel')"
                        :context="context"
                        :frontend_url="frontend_url"
                        :value="value"
                        :languages="languages"
                        @submit="submit" />

    <tour-form  v-if="combine === 'tour'"
                :configs="get_config('tour')"
                :traveler_configs="get_traveler_config('tour')"
                :context="context"
                :frontend_url="frontend_url"
                :value="value"
                :languages="languages"
                @submit="submit" />

    <transfer-form  v-if="combine === 'transfer'"
                    :configs="get_config('transfer')"
                    :traveler_configs="get_traveler_config('transfer')"
                    :context="context"
                    :frontend_url="frontend_url"
                    :value="value"
                    :languages="languages"
                    @submit="submit" />

    <car-rental-form  v-if="combine === 'car_rental'"
                      :configs="get_config('car_rental')"
                      :traveler_configs="get_traveler_config('car_rental')"
                      :context="context"
                      :frontend_url="frontend_url"
                      :value="value"
                      :languages="languages"
                      @submit="submit" />

    <tour-package-form  v-if="combine === 'tour_package' || combine === 'tour_package--flight'"
                        :configs="get_config('tour_package')"
                        :traveler_configs="get_traveler_config('tour_package')"
                        :context="context"
                        :frontend_url="frontend_url"
                        :value="value"
                        :languages="languages"
                        :has_flight="combine === 'tour_package--flight'"
                        @submit="submit" />

  </div>
</template>

<script>
import FlightForm from './flight'
import HotelForm from './hotel'
import FlightHotelForm from './flight-hotel'
import TourForm from './tour'
import TransferForm from './transfer'
import TourPackageForm from './tour-package'
import CarRentalForm from './car-rental'
import moment from 'moment'

import {format_query} from '../../utils'

export default {
    components: {
    FlightForm,
    HotelForm,
    FlightHotelForm,
    TourForm,
    TransferForm,
    TourPackageForm,
    CarRentalForm
  },
  props: {
    context: Object,
    frontend_url: String,
    combine: String,
    quick_search_to: Object,
    languages: {type: Object, default: () => ({})},
    language_code: {type: String, default: 'en-US'},
    search_packages: {type: Array, default: () => ([])}
  },
  data () {
    return {
      value: {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'd').format('YYYY-MM-DD'),
        origin: null,
        destination: null,
        route_type: 'round-trip',
        start_time: moment().format('HH:mm'),
        end_time: moment().format('HH:mm'),
        is_transfer_from_airport: true,
        cabin_class: 'Economy',
        paxes: [],
        separate_checkinout: false,
        diff_dropoff_location: false,
        checkin: '',
        checkout: '',
        driver_age: 30
      },
      options: [],
    }
  },
  watch: {
    'value.start_date' (val) {
      const is_transfer = this.combine === 'transfer'
      const is_car = this.combine === 'car_rental'
      if (val && val >= this.value.end_date && (is_transfer || is_car)) {
        this.value.end_date = moment(val).add(1, 'd').format('YYYY-MM-DD')
      }
    },
    quick_search_to: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.value.destination = val
        }
      }
    }
  },
  beforeMount() {

    // fill form from localStorage
    if (this.combine === 'flight' && localStorage.getItem("gq-flight-data")) {
      const data = JSON.parse(localStorage.getItem("gq-flight-data"))
      this.value = { ...this.value, ...data }
    }

    if (this.combine === 'hotel' && localStorage.getItem("gq-hotel-data")) {
      const data = JSON.parse(localStorage.getItem("gq-hotel-data"))
      this.value = { ...this.value, ...data }
    }

    if (this.combine === 'flight--hotel' && localStorage.getItem('gq-flight-hotel-data')) {
      const data = JSON.parse(localStorage.getItem("gq-flight-hotel-data"))
      this.value = { ...this.value, ...data }
    }

    if (this.combine === 'tour' && localStorage.getItem('gq-tour-data')) {
      const data = JSON.parse(localStorage.getItem("gq-tour-data"))
      this.value = { ...this.value, ...data }
    }

    if (this.combine === 'transfer' && localStorage.getItem('gq-transfer-data')) {
      const data = JSON.parse(localStorage.getItem("gq-transfer-data"))
      this.value = { ...this.value, ...data }
    }

    if (this.combine === 'car_rental' && localStorage.getItem('gq-car-rental-data')) {
      const data = JSON.parse(localStorage.getItem("gq-car-rental-data"))
      this.value = { ...this.value, ...data }
    }

    if (
      (this.combine === 'tour_package' || this.combine === 'tour_package--flight')
      && localStorage.getItem('gq-tour-package-data')
    ) {
      const data = JSON.parse(localStorage.getItem("gq-tour-package-data"))
      this.value = { ...this.value, ...data }
    }
  },
  methods: {
    submit () {
      const { 
        origin,
        destination,
        route_type,
        start_date,
        end_date,
        start_time,
        end_time,
        is_transfer_from_airport,
        cabin_class,
        paxes,
        separate_checkinout,
        checkin,
        checkout,
        driver_age,
        diff_dropoff_location 
      } = this.value

      let cart_items = []
      let route = ''

      switch (this.combine) {
        case 'flight': {
          route = 'shopping/processes/flight'
          cart_items = [
            {
              product_code: 'flight',
              expectation: {
                departure_airport_code: origin && origin.type === 'airport' ? origin.code : null,
                arrival_airport_code: destination && destination.type === 'airport' ? destination.code : null,
                departure_date: start_date,
                return_date: end_date,
                departure_time_range: [],
                arrival_time_range: [],
                round_trip: route_type === 'round-trip',
                airline_codes: [],
                cabin_class: cabin_class
              }
            }
          ]

          localStorage.setItem('gq-flight-data', JSON.stringify({
            route_type,
            origin,
            destination,
            start_date,
            end_date,
            paxes,
            cabin_class
          }))
          break
        }
        case 'hotel': {
          if (destination && destination.type === 'property') {
            route = `shopping/products/hotel/${destination.id}`
          } else {
            route = 'shopping/processes/hotel'
          }

          const place_id = destination.type === 'airport' ? destination.code : destination.id

          cart_items = [
            {
              product_code: 'hotel',
              expectation: {
                place_id: destination ? place_id : null,
                place_type: destination && destination.type,
                checkin_date: start_date,
                checkout_date: end_date,
                room_count: 1,
                hotel_separated: false,
                stars: []
              }
            }
          ]

          localStorage.setItem('gq-hotel-data', JSON.stringify({
            destination,
            start_date,
            end_date,
            paxes
          }))
          break
        }
        case 'flight--hotel': {
          route = 'shopping/processes/flight'

          // let relevant_place = null
          // for (let place_type of ['multi_city_vicinity', 'city']) {
          //   if (relevant_place) break
          //   relevant_place = (destination && destination.ancestors || []).find(a => a.type === place_type)
          // }
          // if (!relevant_place) relevant_place = destination
          
          cart_items = [
            {
              product_code: 'flight',
              expectation: {
                departure_airport_code: origin && origin.type === 'airport' ? origin.code : null,
                arrival_airport_code: destination && destination.type === 'airport' ? destination.code : null,
                departure_date: start_date,
                return_date: end_date,
                departure_time_range: [],
                arrival_time_range: [],
                round_trip: route_type === 'round-trip',
                airline_codes: [],
                cabin_class: cabin_class
              }
            },
            {
              product_code: 'hotel',
              expectation: {
                place_id: destination && destination.type === 'airport' ? destination.code : null,
                place_type: 'airport',
                checkin_date: separate_checkinout ? checkin : start_date,
                checkout_date: separate_checkinout ? checkout : end_date,
                room_count: 1,
                hotel_separated: separate_checkinout,
                stars: []
              }
            }
          ]

          localStorage.setItem('gq-flight-hotel-data', JSON.stringify({
            origin,
            destination,
            start_date,
            end_date,
            paxes,
            cabin_class,
            separate_checkinout,
            checkin,
            checkout
          }))
          break
        }
        case 'tour': {
          route = 'shopping/processes/tour'
          cart_items = [
            {
              product_code: 'tour',
              expectation: {
                place_id: destination.id,
                place_type: destination.type,
                airport_code: destination.code,
                start_date: start_date,
                end_date: end_date,
              }
            },
          ]

          localStorage.setItem('gq-tour-data', JSON.stringify({
            destination,
            start_date,
            end_date,
            paxes
          }))
          break
        }
        case 'transfer': {
          route = 'shopping/processes/transfer'

          const airport = is_transfer_from_airport ? origin : destination
          const des = !is_transfer_from_airport ? origin : destination

          cart_items = [
            {
              product_code: 'transfer',
              expectation: {
                airport_id: airport.id,
                place_id: des.id,
                place_type: des.type,
                from_airport: is_transfer_from_airport,
                round_trip: route_type === 'round-trip',
                pickup_date: start_date,
                pickup_time: start_time,
                return_date: end_date,
                return_time: end_time,
              }
            },
          ]

          localStorage.setItem('gq-transfer-data', JSON.stringify({
            route_type,
            origin,
            destination,
            start_date,
            end_date,
            start_time,
            end_time,
            is_transfer_from_airport,
            paxes
          }))
          break
        }
        case 'car_rental': {
          route = 'shopping/processes/car_rental'
          const origin_code = origin && origin.code
          const destination_code = destination && destination.code
          cart_items = [
            {
              product_code: 'car_rental',
              expectation: {
                pickup_place: origin_code,
                dropoff_place: diff_dropoff_location ? destination_code : origin_code,
                diff_dropoff_place: diff_dropoff_location,
                pickup_date: start_date,
                pickup_time: start_time,
                dropoff_date: end_date,
                dropoff_time: end_time,
                driver_age: driver_age
              }
            },
          ]

          localStorage.setItem('gq-car-rental-data', JSON.stringify({
            origin,
            destination,
            start_date,
            end_date,
            start_time,
            end_time,
            driver_age,
            diff_dropoff_location: destination ? true : false
          }))
          break
        }
        default: {
          // tour package
          route = 'shopping/processes/tour_package'
          cart_items = [
            {
              product_code: 'tour_package',
              expectation: {
                place_id: destination && destination.id,
                place_type: destination && destination.type,
                start_date: start_date
              }
            }
          ]

          localStorage.setItem('gq-tour-package-data', JSON.stringify({
            route_type,
            origin,
            destination,
            start_date,
            end_date,
            paxes,
            cabin_class
          }))
          break
        }
      }

      // console.log(query);
      // return

      const query = {
        paxes: JSON.stringify(paxes),
        cart_items: JSON.stringify(cart_items),
        language_code: this.language_code
      }

      const url = `${this.frontend_url}/${route}?${format_query(query)}`
      if (process.env.NODE_ENV === 'development') {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    get_config (combine) {
      const product_codes = combine.split('--')

      const context_products = this.context.products || []

      let result = context_products.filter(i => product_codes.includes(i.code)) || []

      const sorted_traveler_types = result.sort((a, b) => {
        const a_type_length = a.traveler_types ? a.traveler_types.length : 10
        const b_type_length = b.traveler_types ? b.traveler_types.length : 10
        return a_type_length - b_type_length
      })
      const traveler_types = sorted_traveler_types.length > 0
        ? sorted_traveler_types[0].traveler_types
        : []

      const sorted_cabin_classes = result.sort((a, b) => {
        const a_cabin_length = a.cabin_classes ? a.cabin_classes.length : 10
        const b_cabin_length = b.cabin_classes ? b.cabin_classes.length : 10
        return a_cabin_length - b_cabin_length
      })
      const cabin_classes = sorted_cabin_classes.length > 0
        ? sorted_cabin_classes[0].cabin_classes
        : []

      return {
        traveler_types,
        cabin_classes
      }
    },
    get_traveler_config (combine) {
      const sorted_combine = combine.split('--').sort((a, b) => a - b)
      const search_package = this.search_packages.find(i => {
        const sorted_codes = i.product_codes.sort((a, b) => a - b)
         return JSON.stringify(sorted_codes) === JSON.stringify(sorted_combine)
      }) || {}

      const { traveler_limit_type, total_traveler_limit, traveler_limits = [] } = search_package

      let max_adults = traveler_limits.find(i => i.traveler_type === 'adult')
      max_adults = max_adults ? parseInt(max_adults.limit, 10) : undefined

      let max_children = traveler_limits.find(i => i.traveler_type === 'child')
      max_children = max_children ? parseInt(max_children.limit, 10) : undefined

      let max_infants = traveler_limits.find(i => i.traveler_type === 'infant')
      max_infants = max_infants ? parseInt(max_infants.limit, 10) : undefined

      return {
        traveler_limit_type: traveler_limit_type || undefined,
        total_traveler_limit: total_traveler_limit || undefined,
        max_adults,
        max_children,
        max_infants
      }
    }
  }
}
</script>
