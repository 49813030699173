<template>
  <q-field v-bind="v_bind" :label="label" style="cursor: pointer">
    <template v-slot:control>
      <q-item-label lines="1">{{ display_value }}</q-item-label>
    </template>
    <template v-slot:append v-if="append_icon">
      <q-icon :name="append_icon"/>
    </template>
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon"/>
    </template>
    <q-menu>
      <q-card style="max-width: 400px; width: 100vw">
        <template v-if="with_rooms">
          <template v-for="(room) in rooms">
            <q-item :key="room.index">
              <q-item-section>
                <q-item-label class="text-weight-medium text-capitalize">{{ room_label }} {{ room.index }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-item-label>
                  <q-btn round dense size="sm" unelevated
                         @click="remove_room(room)"
                         v-if="room.index > 1"
                         icon="close" color="red"/>
                </q-item-label>
              </q-item-section>
            </q-item>
            <div class="q-px-md" :key="`room-${room.index}`">
              <q-item-section class="q-pt-none">
                <inputs v-model="room.paxes"
                        :max_adults="max_adults"
                        :max_children="max_children"
                        :max_infants="max_infants"
                        :infant_age="infant_age"
                        :child_age="child_age"
                        :ages="ages.filter(i => i.room === room.index)"
                        :languages="languages"
                        :adults_label="adults_label"
                        :children_label="children_label"
                        :infants_label="infants_label"
                        :traveler_types="traveler_types"
                        :traveler_limit_type="traveler_limit_type"
                        :total_traveler_limit="total_traveler_limit"
                      />
              </q-item-section>
            </div>
            <q-separator :key="`separator-${room.index}`"/>
          </template>
          <q-card-section>
            <div class="q-row q-col-gutter-sm">
              <div class="q-col-12" v-if="rooms.length < max_rooms">
                <q-item-label @click="add_room" class="text-primary text-weight-medium cursor-pointer text-caption text-capitalize">
                  <span>+ {{ add_room_label }}</span>
                </q-item-label>
              </div>
              <div class="q-col-12">
                <q-item-label caption>
                  <span>{{ max_room_label }}</span>
                </q-item-label>
              </div>
              <div class="q-col-12">
                <q-item-label caption>
                  <span>{{ age_note_label }}</span>
                </q-item-label>
              </div>
            </div>
          </q-card-section>
        </template>
        <template v-else>
          <q-card-section>
            <inputs v-model="paxes"
                    :max_adults="max_adults"
                    :max_children="max_children"
                    :max_infants="max_infants"
                    :infant_age="infant_age"
                    :child_age="child_age"
                    :ages="ages"
                    :languages="languages"
                    :adults_label="adults_label"
                    :children_label="children_label"
                    :infants_label="infants_label"
                    :traveler_types="traveler_types"
                    :traveler_limit_type="traveler_limit_type"
                    :total_traveler_limit="total_traveler_limit"
                  />
          </q-card-section>
        </template>
      </q-card>
    </q-menu>
  </q-field>
</template>
<script>
import Inputs from './inputs'

export default {
  components: {Inputs},
  props: {
    label: {type: String, default: 'Passengers'},
    append_icon: {type: String,},
    prepend_icon: {type: String,},
    outlined: {type: Boolean, default: false},
    with_rooms: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    infant_age: {type: Number, default: 2},
    child_age: {type: Number, default: 17},
    max_adults: {type: Number, default: 9},
    max_children: {type: Number, default: 6},
    max_infants: {type: Number, default: 3},
    total_traveler_limit: {type: Number, default: 9},
    traveler_limit_type: {type: String, default: 'per_type'},
    max_rooms: {type: Number, default: 3},
    value: {type: Array, default: () => ([])},
    no_infant: {type: Boolean, default: false},
    languages: {type: Object, default: () => ({})},
    traveler_types: {type: Array, default: () => ([])}
  },
  data () {
    return {
      paxes: {
        adult: 2,
        child: 0,
        infant: 0,
      },
      rooms: [],
      ages: [],
      ages_from_cached: [],
      is_parsed: false
    }
  },
  created () {
    if (!this.value.length) {
      this.$emit('input', [
        {type: 'adult', no: 0, room: 1, age: this.child_age + 1},
        {type: 'adult', no: 1, room: 1, age: this.child_age + 1}
      ])
      this.rooms = [this.init_room()]
    }

    this.parse_value()

    setTimeout(() => {
      this.is_parsed = true
    }, 1000);
  },
  computed: {
    v_bind () {
      return {
        outlined: this.outlined,
        dense: this.dense,
        value: this.display_value
      }
    },
    adults_label () {
      return this.languages['common.adults'] || 'Adults'
    },
    adult_label () {
      return this.languages['common.adult'] || 'Adult'
    },
    children_label () {
      return this.languages['common.children'] || 'Children'
    },
    child_label () {
      return this.languages['common.child'] || 'Child'
    },
    infants_label () {
      return this.languages['common.infants'] || 'Infants'
    },
    infant_label () {
      return this.languages['common.infant'] || 'Infant'
    },
    rooms_label () {
      return this.languages['common.rooms'] || 'Rooms'
    },
    room_label () {
      return this.languages['common.room'] || 'Room'
    },
    age_note_label () {
      const init = 'Age of children/infants should be considered from the date of departure.'
      return this.languages['components.shopping.forms.common.pax-picker.age-note'] || init
    },
    max_room_label () {
      const init = 'Max 3 rooms are allowed per booking'
      return this.languages['components.shopping.forms.common.pax-picker.max-room-desc'] || init
    },
    add_room_label () {
      return this.languages['common.add-room'] || 'Add Room'
    },
    display_value () {
      let result = []
      let parse_paxes = (paxes) => {
        let result = [
          `${paxes.adult} ${paxes.adult > 1 ? this.adults_label : this.adult_label}`
        ]
        if (paxes.child) {
          result.push(`${paxes.child} ${paxes.child > 1 ? this.children_label : this.child_label}`)
        }
        if (paxes.infant) {
          result.push(`${paxes.infant} ${paxes.infant > 1 ? this.infants_label : this.infant_label}`)
        }
        return result
      }
      let changes = this.parse_changes()
      let paxes = {
        adult: changes.filter(p => p.type === 'adult').length,
        child: changes.filter(p => p.type === 'child').length,
        infant: changes.filter(p => p.type === 'infant').length,
      }
      if (this.with_rooms) {
        result.push(`${this.rooms.length} ${this.rooms.length > 1 ? this.rooms_label : this.room_label}`)
        result = result.concat(parse_paxes(paxes))
      } else {
        result = parse_paxes(paxes)
      }
      return result.join(', ')
    }
  },
  methods: {
    init_age (type) {
      if (type === 'adult') {
        return this.child_age + 1
      } else if (type === 'child') {
        return this.init_age
      } else {
        return 0
      }
    },
    parse_changes () {
      let result = []
      if (this.with_rooms) {
        this.rooms.forEach((r, index) => {
          Object.keys(r.paxes).forEach(pax_type => {
            let pax_no = r.paxes[pax_type]
            Array.from(Array(pax_no).keys()).forEach(n => {
              const params = {
                type: pax_type,
                room: r.index,
                no: n
              }

              result.push(params)
            })
          })
        })
      } else {
        Object.keys(this.paxes).forEach(pax_type => {
          let pax_no = this.paxes[pax_type]
          Array.from(Array(pax_no).keys()).forEach(n => {
            const params = {
              type: pax_type,
              no: n
            }
            result.push(params)
          })
        })
      }


      return result
    },
    emit_changes () {
      const result = this.parse_changes()

      if (this.is_parsed) {
        this.$emit('input', result)
      }

      let ages = [...this.ages]

      this.ages = result.map(item => {
        const found = this.with_rooms
          ? ages.find(i => i.no === item.no && i.pax_type === item.type && i.room === item.room)
          : ages.find(i => i.no === item.no && i.pax_type === item.type)

        let age_value = 0
        if (item.type === 'adult') age_value = this.child_age + 1
        if (item.type === 'child') age_value = this.infant_age

        const params = {
          pax_type: item.type,
          value: age_value,
          no: item.no,
          room: item.room || 1
        }

        if (found) params.value = found.value

        return params
      })
    },
    init_room (index) {
      return {
        index: index,
        paxes: {adult: 2, child: 0, infant: 0}
      }
    },
    add_room () {
      this.rooms.push(this.init_room(this.rooms.length + 1))
    },
    remove_room (room) {
      this.rooms = this.rooms.filter(i => i.index !== room.index)
    },
    parse_value () {
      if (!this.value.length) return
      
      let paxes = {
        adult: 0,
        infant: 0,
        child: 0
      }
      let rooms = []
      let ages = []
      this.value.forEach((pax, index) => {
        let pax_type = pax.type
        let room_index = pax.room || 1
        let room = rooms.find(r => r.index === room_index)
        if (!room) {
          room = this.init_room(room_index)
          room.paxes.adult = 0
          rooms.push(room)
        }
        room.paxes[pax_type]++
        paxes[pax_type]++

        const params = {
          pax_type: pax.type,
          value: pax.age || this.init_age(pax.type),
          room: pax.room || 1
        }

        if (pax.type === 'infant') {
          const inf = this.with_rooms
            ? ages.filter(i => i.pax_type === 'infant' && i.room === pax.room)
            : ages.filter(i => i.pax_type === 'infant')

          const index = inf.length > 0 ? inf[inf.length - 1].no + 1 : 0
          params.no = index
        }

        if (pax.type === 'child') {
          const chd = this.with_rooms
            ? ages.filter(i => i.pax_type === 'child' && i.room === pax.room)
            : ages.filter(i => i.pax_type === 'child')

          const index = chd.length > 0 ? chd[chd.length - 1].no + 1 : 0
          params.no = index
        }

        if (pax.type === 'adult') {
          const adl = this.with_rooms
            ? ages.filter(i => i.pax_type === 'adult' && i.room === pax.room)
            : ages.filter(i => i.pax_type === 'adult')

          const index = adl.length > 0 ? adl[adl.length - 1].no + 1 : 0
          params.no = index
        }

        ages.push(params)
      })
      if (paxes.adult === 0) paxes.adult++

      this.paxes = paxes
      this.rooms = rooms.length ? rooms : [this.init_room(1)]
      this.ages_from_cached = ages
    },
  },
  watch: {
    'paxes': {
      deep: true,
      handler () {
        this.emit_changes()
      },
    },
    'rooms': {
      deep: true,
      handler () {
        this.emit_changes()
      }
    },
    'ages_from_cached': {
      deep: true,
      handler () {
        this.ages = this.ages_from_cached
      }
    },
    'ages': {
      deep: true,
      handler () {
        let result = [...this.value]
        result = result.map(item => {
          const found = this.with_rooms
            ? this.ages.find(i => i.pax_type === item.type && i.no === item.no && i.room === item.room)
            : this.ages.find(i => i.pax_type === item.type && i.no === item.no)

          if (found) {
            item.age = found.value
          }
          return item
        })

        this.$emit('input', result)
      }
    }
  }
}
</script>
